import { safeFetchAPI } from '@/api';
import { utils } from '@/helpers';
import { Setter } from '@/legacy-types';
import { InfobipNumber, InfoBipNumberPreview } from '@/types';
import { create } from 'zustand';
import { MAX_AT_ONCE } from './useTelnyxPhoneManagerStore';

export type InfoBipPhoneManagerSearchFilters = {
	code?: string;
};

export const InfoBipPMTabs = ['new', 'existing'];
type InfoBipPMTab = (typeof InfoBipPMTabs)[number];

type InfoBipPhoneManagerStore = {
	selectedNumbers: Map<string, boolean>;

	search: string;

	selectedTab: InfoBipPMTab;

	filters: InfoBipPhoneManagerSearchFilters;

	pagination: {
		page: number;
		perPage: number;
	};

	loadingAvailibleNumbers: boolean;
	loadingExistingNumbers: boolean;

	existingNumbers: InfobipNumber[];
	availibleNumbers: InfoBipNumberPreview[];

	loadingDelete: boolean;
	loadingPurchase: boolean;
};

type InfoBipPhoneManagerActions = {
	setSelectedTab: (tab: InfoBipPMTab) => void;
	setSearch: (search: string) => void;

	loadExistingNumbers: () => Promise<void>;
	loadAvailibleNumbers: () => Promise<void>;

	purchaseAllSelected: () => Promise<void>;
	purchasePhone: (number: string, bulk?: boolean) => Promise<string>;

	deleteAllSelected: () => Promise<void>;
	deletePhone: (number: string, bulk?: boolean) => Promise<string>;

	handleSelectNumber: (number: string, force?: boolean) => void;
	handleSelectPage: (force?: boolean) => void;
	handleSelectMax: (force?: boolean) => void;

	setPage: (page: number) => void;
	setPerPage: (perPage: number) => void;
	setFilters: (filters: Setter<InfoBipPhoneManagerSearchFilters>) => void;

	getNumbers: () => (InfoBipNumberPreview | InfobipNumber)[];
	getPageNumbers: () => (InfoBipNumberPreview | InfobipNumber)[];
	isPageSelected: () => boolean;

	clearSelected: () => void;

	reset: () => void;
};

const initialState: InfoBipPhoneManagerStore = {
	selectedTab: 'existing',
	search: '',
	filters: {
		code: undefined,
	},
	pagination: {
		page: 1,
		perPage: 5,
	},
	selectedNumbers: new Map(),
	existingNumbers: [],
	availibleNumbers: [],
	loadingAvailibleNumbers: true,
	loadingExistingNumbers: true,

	loadingDelete: false,
	loadingPurchase: false,
};

// marketing.GET("/ib/search/:uid/:code", s.searchInfobipPhones) // Search for new numbers
// marketing.PUT("/ib/buy/:uid/:number", s.manualInfobipBuyPhone) // Buy a number
// marketing.DELETE("/ib/:uid/:phone", s.deleteInfobipNumber) // Delete a number
// marketing.GET("/ib/campaign/numbers/:uid", s.debugGetInfobipNumbers) // Get users existing numbers

// Zustand store for InfoBipPhoneManager
export const useInfoBipPhoneManagerStore = create<InfoBipPhoneManagerStore & InfoBipPhoneManagerActions>((set, get) => ({
	...initialState,

	setSelectedTab: (tab) => {
		set({ selectedTab: tab, search: '' });
	},

	setSearch: (search) => {
		set({ search });
	},

	handleSelectNumber: (number, force) => {
		set((state) => {
			const selectedNumbers = new Map(state.selectedNumbers);
			const totalSelected = selectedNumbers.size;

			if (totalSelected >= MAX_AT_ONCE) {
				if (force === undefined) {
					utils.toaster?.addToast?.({ message: 'You can only select up to 100 numbers at a time', type: 'danger', placement: 'top' });
				}
				return { selectedNumbers };
			}

			const setSelected = force ?? !selectedNumbers.get(number);
			if (setSelected) {
				selectedNumbers.set(number, true);
			} else {
				selectedNumbers.delete(number);
			}
			return { selectedNumbers };
		});
	},

	loadExistingNumbers: async () => {
		set({ loadingExistingNumbers: true });

		const [data, error] = await safeFetchAPI('/ib/campaign/numbers/:uid', {
			method: 'GET',
		});

		if (error) {
			utils.toaster?.addToast?.({
				message: 'Failed to load existing numbers',
				type: 'danger',
				placement: 'top',
			});
			return;
		}

		set({ existingNumbers: data ?? [], loadingExistingNumbers: false });
	},

	loadAvailibleNumbers: async () => {
		const { code } = get().filters;
		if (!code) {
			utils.toaster?.addToast?.({ message: 'Please enter a code', type: 'danger', placement: 'top' });
			return;
		}

		set({ loadingAvailibleNumbers: true });
		const [data, error] = await safeFetchAPI(`/ib/search/:uid/${code}`, {
			method: 'GET',
		});

		if (error) {
			utils.toaster?.addToast?.({ message: 'Failed to load availible numbers', type: 'danger', placement: 'top' });
			return;
		}

		set({ availibleNumbers: data ?? [], loadingAvailibleNumbers: false });
	},

	purchaseAllSelected: async () => {
		const selectedNumbers = get().selectedNumbers;
		// If somehow over 100 selected, throw an error
		if (selectedNumbers.size > MAX_AT_ONCE) {
			utils.toaster?.addToast?.({ message: 'You can only purchase up to 100 numbers at a time', type: 'danger', placement: 'top' });
			return;
		}

		set({ loadingPurchase: true });
		let index = 0;
		let indexNumber = '';
		utils.toaster?.addToast?.({
			message: () => `Purchasing phone number ${indexNumber} (${index}/${selectedNumbers.size})`,
			type: 'info',
			placement: 'top',
			toastKey: 'info-key',
			duration: 0,
			interval: 100,
		});
		const errors: string[] = [];
		for (const number of Array.from(selectedNumbers.keys())) {
			index++;
			indexNumber = number;

			const error = await get().purchasePhone(number);
			if (error) {
				errors.push(error);
				utils.toaster?.addToast?.({
					message: `Error purchasing ${number}: ${error}`,
					type: 'danger',
					placement: 'bottomRight',
					duration: 10000,
				});
			}
		}

		utils.toaster?.removeToast?.('info-key');

		const totalErrors = errors.length;
		const totalPurchased = selectedNumbers.size - totalErrors;
		utils.toaster?.addToast?.({
			message: `Purchased ${totalPurchased} of ${selectedNumbers.size} phone numbers`,
			type: 'success',
			placement: 'top',
			toastKey: 'success-key',
			duration: 3000,
		});

		if (totalErrors > 0) {
			utils.toaster?.addToast?.({ message: `Failed to purchase ${totalErrors} phone numbers`, type: 'danger', placement: 'top' });
		}

		get().loadExistingNumbers();

		set({ loadingPurchase: false });
	},

	deleteAllSelected: async () => {
		const selectedNumbers = get().selectedNumbers;

		let index = 0;
		let indexNumber = '';
		utils.toaster?.addToast?.({
			message: () => `Deleting phone number ${indexNumber} (${index}/${selectedNumbers.size})`,
			type: 'info',
			placement: 'top',
			toastKey: 'info-key',
			duration: 0,
			interval: 100,
		});
		set({ loadingDelete: true });
		const errors: string[] = [];
		for (const number of Array.from(selectedNumbers.keys())) {
			index++;
			indexNumber = number;

			const error = await get().deletePhone(number);
			if (error) {
				errors.push(error);
				utils.toaster?.addToast?.({
					message: `Error deleting ${number}: ${error}`,
					type: 'danger',
					placement: 'bottomRight',
					duration: 10000,
				});
			}
		}

		set({ loadingDelete: false });
		utils.toaster?.removeToast?.('info-key');

		const totalErrors = errors.length;
		const totalDeleted = selectedNumbers.size - totalErrors;
		utils.toaster?.addToast?.({
			message: `Deleted ${totalDeleted} of ${selectedNumbers.size} phone numbers`,
			type: 'success',
			placement: 'top',
			toastKey: 'success-key',
			duration: 3000,
		});
		if (totalErrors > 0) {
			utils.toaster?.addToast?.({ message: `Failed to delete ${totalErrors} phone numbers`, type: 'danger', placement: 'top' });
		}
	},

	handleSelectMax: () => {
		// If they already selected all the numbers, clear the selected numbers
		if (get().selectedNumbers.size === get().getNumbers().length || get().selectedNumbers.size >= MAX_AT_ONCE) {
			get().selectedNumbers.clear();
			return;
		}

		const numbers = get().getNumbers();
		// IF this page is selected, we start on next one, if it's not we start on this one
		const start = get().isPageSelected() ? get().pagination.page + 1 : get().pagination.page;
		const end = Math.min(start + MAX_AT_ONCE, numbers.length);
		for (let i = start - 1; i < end; i++) {
			get().handleSelectNumber(numbers[i].number, true);
		}
	},

	deletePhone: async (number, bulk) => {
		if (!bulk) set({ loadingDelete: true });

		const [_, error] = await safeFetchAPI(`/ib/:uid/${number}`, {
			method: 'DELETE',
		});

		if (error && !bulk) {
			utils.toaster?.addToast?.({ message: `Error deleting phone: ${number}`, type: 'danger', placement: 'top' });
		}

		if (!bulk) set({ loadingDelete: false });

		set((state) => {
			const existingNumbers = state.existingNumbers.filter((n) => n.number !== number);
			return { existingNumbers };
		});

		return error || '';
	},

	purchasePhone: async (number, bulk) => {
		if (!bulk) set({ loadingPurchase: true });

		const [_, error] = await safeFetchAPI(`/ib/buy/:uid/${number}`, {
			method: 'PUT',
		});

		if (error) {
			utils.toaster?.addToast?.({ message: `Error purchasing phone: ${number}`, type: 'danger', placement: 'top' });
		}

		set({ loadingPurchase: false });

		return error || '';
	},

	clearSelected: () => {
		get().selectedNumbers.clear();
	},

	getNumbers: () => {
		const { search } = get();
		const numbers = get().selectedTab === 'existing' ? get().existingNumbers : get().availibleNumbers;
		if (!search) return numbers;
		return numbers.filter((number) => number.number.includes(search));
	},

	getPageNumbers: () => {
		const { page, perPage } = get().pagination;
		const numbers = get().getNumbers();
		return numbers?.slice((page - 1) * perPage, page * perPage) ?? [];
	},

	isPageSelected: () => {
		const numbers = get().getPageNumbers();
		if (numbers.length === 0) return false;
		return numbers.every((number) => get().selectedNumbers.get(number.number));
	},
	handleSelectPage: (force) => {
		const numbers = get().getPageNumbers();
		const areAllSelected = get().isPageSelected();
		// IF the whole page is not selected, select all of the numbers on the page
		if (!areAllSelected) {
			numbers.forEach((number) => get().handleSelectNumber(number.number, force || true));
		}
		// If they are all selected we unselect them all
		else {
			numbers.forEach((number) => get().handleSelectNumber(number.number, force || false));
		}
	},

	setPage: (page: number) => {
		set({ pagination: { ...get().pagination, page } });
	},

	setPerPage: (perPage: number) => {
		set({ pagination: { ...get().pagination, perPage } });
	},

	setFilters: (filters) => {
		const currFilters = get().filters;
		const nextFilters = typeof filters === 'function' ? filters(currFilters) : filters;
		set({ filters: nextFilters, pagination: { ...get().pagination, page: 1 } });
	},

	reset: () => {
		set(initialState);
	},
}));
