import { safeFetchAPI } from '@/api';
import { getTelnyxUser, searchPhones, TelnyxNumberType, TelnyxUser } from '@/api/telnyx';
import { utils } from '@/helpers';
import { Setter } from '@/legacy-types';
import { create } from 'zustand';

export type TelnyxPhoneManagerSearchFilters = {
	code?: string;
	type?: string;
};

export const TelnyxPMTabs = ['new', 'existing'];
type TelnyxPMTab = (typeof TelnyxPMTabs)[number];

type TelnyxPhoneManagerStore = {
	loadingDelete: boolean;
	loadingPurchase: boolean;
	loadingUser: boolean;
	loadingNumbers: boolean;
	filters: TelnyxPhoneManagerSearchFilters;
	search: string;
	telnyxUser: TelnyxUser | undefined;
	availibleNumbers: TelnyxNumberType[];
	selectedNumbers: Map<string, boolean>;

	selectedTab: TelnyxPMTab;

	pagination: {
		page: number;
		perPage: number;
	};
};

type TelnyxPhoneManagerActions = {
	setSelectedTab: (tab: TelnyxPMTab) => void;

	loadUser: () => Promise<void>;
	searchNumbers: () => Promise<void>;

	getPageNumbers: () => TelnyxNumberType[];
	setPage: (page: number) => void;
	setPerPage: (perPage: number) => void;
	setFilters: (filters: Setter<TelnyxPhoneManagerSearchFilters>) => void;
	setSearch: (search: string) => void;

	handleSelectNumber: (number: string, force?: boolean) => void;
	handleSelectPage: (force?: boolean) => void;
	handleSelectAll: (force?: boolean) => void;
	selectUntilMax: () => void;
	clearSelected: () => void;

	purchaseAllSelected: () => Promise<void>;
	purchasePhone: (number: string, bulk?: boolean) => Promise<string>;

	deleteAllSelected: () => Promise<void>;
	deletePhone: (number: string) => Promise<string>;

	getNumbers: () => TelnyxNumberType[];
	isPageSelected: () => boolean;
	isAllSelected: () => boolean;

	reset: () => void;
};

const initialState: TelnyxPhoneManagerStore = {
	selectedTab: 'existing',
	telnyxUser: undefined,
	availibleNumbers: [],
	loadingUser: true,
	loadingNumbers: true,
	loadingPurchase: false,
	loadingDelete: false,
	selectedNumbers: new Map(),
	search: '',
	filters: {
		code: undefined,
		type: 'local',
	},
	pagination: {
		page: 1,
		perPage: 5,
	},
};

export const MAX_AT_ONCE = 100;

// Zustand store for TelnyxPhoneManager
export const useTelnyxPhoneManagerStore = create<TelnyxPhoneManagerStore & TelnyxPhoneManagerActions>((set, get) => ({
	...initialState,
	setSelectedTab: (tab) => {
		get().clearSelected();
		get().setPage(1);

		if (tab === 'existing' && !get().telnyxUser) {
			get().loadUser();
		}

		set({ selectedTab: tab, selectedNumbers: new Map(), search: '' });
	},

	setSearch: (search) => {
		set({ search });
	},
	loadUser: async () => {
		set({ loadingUser: true });
		const telnyxUser = await getTelnyxUser({});
		set({ telnyxUser, loadingUser: false });
	},
	searchNumbers: async () => {
		const { code, type = 'local' } = get().filters;
		if (!code) {
			utils.toaster?.addToast?.({ message: 'Area code is required', type: 'danger', placement: 'top' });
			return;
		}

		set({ loadingNumbers: true });
		const numbers = await searchPhones(code ?? '', type);
		set({ availibleNumbers: numbers ?? [], selectedNumbers: new Map(), loadingNumbers: false });
	},
	setFilters: (filters) => {
		const currFilters = get().filters;
		const nextFilters = typeof filters === 'function' ? filters(currFilters) : filters;
		set({ filters: nextFilters, pagination: { ...get().pagination, page: 1 } });
	},
	handleSelectNumber: (number, force) => {
		set((state) => {
			const selectedNumbers = new Map(state.selectedNumbers);
			const totalSelected = selectedNumbers.size;

			if (totalSelected >= MAX_AT_ONCE) {
				if (force === undefined) {
					utils.toaster?.addToast?.({ message: 'You can only select up to 100 numbers at a time', type: 'danger', placement: 'top' });
				}
				return { selectedNumbers };
			}

			const setSelected = force ?? !selectedNumbers.get(number);
			if (setSelected) {
				selectedNumbers.set(number, true);
			} else {
				selectedNumbers.delete(number);
			}
			return { selectedNumbers };
		});
	},
	getPageNumbers: () => {
		const { page, perPage } = get().pagination;
		const numbers = get().getNumbers();
		return numbers?.slice((page - 1) * perPage, page * perPage) ?? [];
	},
	setPage: (page) => {
		set({ pagination: { ...get().pagination, page } });
	},
	setPerPage: (perPage) => {
		set({ pagination: { ...get().pagination, perPage } });
	},
	isPageSelected: () => {
		const numbers = get().getPageNumbers();
		if (numbers.length === 0) return false;
		return numbers.every((number) => get().selectedNumbers.get(number.phone_number));
	},
	handleSelectPage: (force) => {
		const numbers = get().getPageNumbers();
		const areAllSelected = get().isPageSelected();
		// IF the whole page is not selected, select all of the numbers on the page
		if (!areAllSelected) {
			numbers.forEach((number) => get().handleSelectNumber(number.phone_number, force || true));
		}
		// If they are all selected we unselect them all
		else {
			numbers.forEach((number) => get().handleSelectNumber(number.phone_number, force || false));
		}
	},
	getNumbers: () => {
		const { search } = get();
		const numbers = get().selectedTab === 'existing' ? get().telnyxUser?.numbers ?? [] : get().availibleNumbers;
		if (!search) return numbers;
		return numbers.filter((number) => number.phone_number.includes(search));
	},
	isAllSelected: () => {
		const numbers = get().getNumbers();
		if (numbers.length === 0) return false;
		return numbers.every((number) => get().selectedNumbers.get(number.phone_number));
	},
	handleSelectAll: (force) => {
		const numbers = get().getNumbers();
		const areAllSelected = get().isAllSelected();
		// If the whole list is not selected, select all of the numbers
		if (!areAllSelected) {
			numbers.forEach((number) => get().handleSelectNumber(number.phone_number, force || true));
		}
		// If they are all selected we unselect them all
		else {
			numbers.forEach((number) => get().handleSelectNumber(number.phone_number, force || false));
		}
	},
	// Selects the next MAX_AT_ONCE numbers starting from the current page
	selectUntilMax: () => {
		// If they already selected all the numbers, clear the selected numbers
		if (get().isAllSelected()) {
			get().clearSelected();
			return;
		}

		const numbers = get().getNumbers();
		// IF this page is selected, we start on next one, if it's not we start on this one
		const start = get().isPageSelected() ? get().pagination.page + 1 : get().pagination.page;
		const end = Math.min(start + MAX_AT_ONCE, numbers.length);
		for (let i = start - 1; i < end; i++) {
			get().handleSelectNumber(numbers[i].phone_number, true);
		}
	},

	purchaseAllSelected: async () => {
		const selectedNumbers = get().selectedNumbers;
		// If somehow over 100 selected, throw an error
		if (selectedNumbers.size > MAX_AT_ONCE) {
			utils.toaster?.addToast?.({ message: 'You can only purchase up to 100 numbers at a time', type: 'danger', placement: 'top' });
			return;
		}

		set({ loadingPurchase: true });
		let index = 0;
		let indexNumber = '';
		utils.toaster?.addToast?.({
			message: () => `Purchasing phone number ${indexNumber} (${index}/${selectedNumbers.size})`,
			type: 'info',
			placement: 'top',
			toastKey: 'info-key',
			duration: 0,
			interval: 100,
		});
		const errors: string[] = [];
		for (const number of Array.from(selectedNumbers.keys())) {
			index++;
			indexNumber = number;

			const error = await get().purchasePhone(number);
			if (error) {
				errors.push(error);
				utils.toaster?.addToast?.({
					message: `Error purchasing ${number}: ${error}`,
					type: 'danger',
					placement: 'bottomRight',
					duration: 10000,
				});
			}
		}

		utils.toaster?.removeToast?.('info-key');

		const totalErrors = errors.length;
		const totalPurchased = selectedNumbers.size - totalErrors;
		utils.toaster?.addToast?.({
			message: `Purchased ${totalPurchased} of ${selectedNumbers.size} phone numbers`,
			type: 'success',
			placement: 'top',
			toastKey: 'success-key',
			duration: 3000,
		});

		if (totalErrors > 0) {
			utils.toaster?.addToast?.({ message: `Failed to purchase ${totalErrors} phone numbers`, type: 'danger', placement: 'top' });
		}

		get().loadUser();

		set({ loadingPurchase: false });
	},

	deleteAllSelected: async () => {
		const selectedNumbers = get().selectedNumbers;

		let index = 0;
		let indexNumber = '';
		utils.toaster?.addToast?.({
			message: () => `Deleting phone number ${indexNumber} (${index}/${selectedNumbers.size})`,
			type: 'info',
			placement: 'top',
			toastKey: 'info-key',
			duration: 0,
			interval: 100,
		});
		set({ loadingDelete: true });
		const errors: string[] = [];
		for (const number of Array.from(selectedNumbers.keys())) {
			index++;
			indexNumber = number;

			const error = await get().deletePhone(number);
			if (error) {
				errors.push(error);
				utils.toaster?.addToast?.({
					message: `Error deleting ${number}: ${error}`,
					type: 'danger',
					placement: 'bottomRight',
					duration: 10000,
				});
			}
		}

		set({ loadingDelete: false });
		utils.toaster?.removeToast?.('info-key');

		const totalErrors = errors.length;
		const totalDeleted = selectedNumbers.size - totalErrors;
		utils.toaster?.addToast?.({
			message: `Deleted ${totalDeleted} of ${selectedNumbers.size} phone numbers`,
			type: 'success',
			placement: 'top',
			toastKey: 'success-key',
			duration: 3000,
		});
		if (totalErrors > 0) {
			utils.toaster?.addToast?.({ message: `Failed to delete ${totalErrors} phone numbers`, type: 'danger', placement: 'top' });
		}
	},
	deletePhone: async (number: string, bulk?: boolean): Promise<string> => {
		if (!bulk) set({ loadingDelete: true });
		const [_, error] = await safeFetchAPI(`/telnyx/:uid/${number}`, {
			method: 'DELETE',
		});

		if (error && !bulk) {
			utils.toaster?.addToast?.({ message: `Error deleting phone: ${number}`, type: 'danger', placement: 'top' });
		}

		if (!bulk) {
			set({ loadingDelete: false });
		}

		set((state) => {
			const telnyxUser = state.telnyxUser;
			if (telnyxUser) {
				telnyxUser.numbers = telnyxUser.numbers?.filter((n) => n.phone_number !== number) ?? [];
			}
			return { telnyxUser };
		});

		return error || '';
	},
	purchasePhone: async (number: string, bulk?: boolean): Promise<string> => {
		if (!bulk) {
			set({ loadingPurchase: true });
		}
		const [_, error] = await safeFetchAPI(`/telnyx/buy/:uid/${number}?skip=true`, {
			method: 'PUT',
		});

		if (error && !bulk) {
			utils.toaster?.addToast?.({ message: `Error purchasing phone: ${number}`, type: 'danger', placement: 'top' });
		}

		if (!bulk) {
			get().loadUser();
			set({ loadingPurchase: false });
		}

		return error || '';
	},
	clearSelected: () => {
		set({ selectedNumbers: new Map() });
	},
	reset: () => {
		set(initialState);
	},
}));
